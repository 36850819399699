import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import FrameL, { Img } from 'component/FrameL';

import './style.css';
import Modal from 'component/Modal';

export default (type = 'things-to-do', hasLinks = true, linksHeading = 'Explore') => ({ cmsData }) => {
    const { sections = [] } = cmsData[type];
    const [isActivitySelected, setIsActivitySelected] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState({});
    const [selectedSectionHeading, setSelectedSectionHeading] = useState('');
    const { Heading: selectedHeading, Photo: selectedPhoto, Description: selectedDesc } = selectedActivity;

    return <>
        <div class="xl:container mx-auto px-4 pb-10 stack-l " style={{ "--space": "var(--s5)" }}>
            {sections.map(({ name, headline, photo, icon, description, activities = [], bannerPhoto }, i) => {
                return <section className="stack-l" style={{ '--space': 'var(--s4)' }} key={i}>
                    <FrameL className="break-out-banner" fixedHeight="92vh" optimizeImageSize={false} Image={bannerPhoto}></FrameL>
                    <section className={`ttd__grid ${i % 2 === 1 ? 'reverse' : ''}`}>
                        <div className="w-full">
                            <FrameL ratio="8:9" Image={photo}></FrameL>
                        </div>

                        <div className="stack-l text-center">
                            <h2 className="h5">{name}</h2>
                            <h3 className="text-serif text-black">{headline}</h3>
                            <div>
                                <Img Image={icon} className="w-1/5 float-left mr-4 mb-4"></Img>
                                <ReactMarkdown className='markdown-bulleted-list'>{description}</ReactMarkdown>
                            </div>

                            {hasLinks && <>
                                <h4 className="h5 text-center">{linksHeading}</h4>
                                <div className="cluster-l" style={{ "--justify": "center", "--space": "var(--s-2)" }}>
                                    {activities.map((act, i) => {
                                        const { Heading } = act;
                                        return <button key={i} className="text-serif font-bold px-1 text-sm" onClick={() => {
                                            setIsActivitySelected(true);
                                            setSelectedActivity(act);
                                            setSelectedSectionHeading(name);
                                        }}>{Heading}</button>
                                    })}
                                </div>
                            </>}
                        </div>
                    </section>
                </section>
            })}

        </div>

        <Modal isOpen={isActivitySelected} onClose={() => {
            setIsActivitySelected(false);
        }}>
            <div className="xl:container xl:mx-auto m-4 p-4 md:p-8 bg-orange-100 bg-opacity-90 border-2 rounded-md border-black">
                <div key={selectedHeading} className="ttd__grid">
                    <div className="max-w-md w-full mx-auto">
                        <FrameL Image={selectedPhoto} ratio="1:1" imgClasses="rounded-full"></FrameL>
                    </div>
                    <div className="text-center">
                        <h3 className="h5">{selectedSectionHeading}</h3>
                        <h3 className="text-serif text-black">{selectedHeading}</h3>
                        <ReactMarkdown>{selectedDesc}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

